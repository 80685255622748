var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.product_amounts,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.product_amounts.indexOf(item) + 1)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":"Y"},on:{"click":function($event){return _vm.updateStatusPackage(item.package_id)}},model:{value:(item.package_status),callback:function ($$v) {_vm.$set(item, "package_status", $$v)},expression:"item.package_status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.promotion_free",fn:function(ref){
var item = ref.item;
return [(item.start_promotion && item.end_promotion)?_c('span',[_vm._v(" วันที่เริ่ม "+_vm._s(item.start_promotion ? item.start_promotion : '-')+" "),_c('br'),_vm._v(" วันที่สิ้นสุด "+_vm._s(item.end_promotion ? item.end_promotion : '-')+" ")]):_vm._e(),(!item.start_promotion && !item.end_promotion)?_c('span',[_vm._v(" - ")]):_vm._e()]}},{key:"item.cut_stock",fn:function(ref){
var item = ref.item;
return [(item.is_stock === 'Y')?_c('span',[_vm._v("ตัดสต๊อค")]):_vm._e(),(item.is_stock !== 'Y')?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }