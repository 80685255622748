<template>
  <v-data-table
    :headers="headers"
    :items="product_amounts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
<!--        <v-dialog-->
<!--          v-model="dialog"-->
<!--          max-width="500px"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            &lt;!&ndash; <div class="d-flex justify-space-between"> &ndash;&gt;-->
<!--            &lt;!&ndash; <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> &ndash;&gt;-->
<!--            <v-btn-->
<!--              color="primary"-->
<!--              dark-->
<!--              class="mb-2"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--            >-->
<!--              เพิ่มอัตราเรทราคาสินค้า(ชิ้น)-->
<!--            </v-btn>-->
<!--            &lt;!&ndash; </div> &ndash;&gt;-->
<!--          </template>-->
<!--          <v-card>-->
<!--            <v-card-title>-->
<!--              <span class="text-h5">{{ formTitle }}</span>-->
<!--            </v-card-title>-->

<!--            <v-card-text>-->
<!--              <v-container>-->
<!--                <v-row>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-select-->
<!--                      :readonly="editedIndex >= 0"-->
<!--                      v-model="product_type_id"-->
<!--                      :items="product_types"-->
<!--                      item-text="product_type_name"-->
<!--                      item-value="product_type_id"-->
<!--                      label="ประเภทสินค้า"-->
<!--                    ></v-select>-->
<!--                  </v-col>-->

<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-btn-->
<!--                      @click="addRate"-->
<!--                      class="float-right" color="secondary">+</v-btn>-->
<!--                  </v-col>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <table style="width: 100%;">-->
<!--                      <thead>-->
<!--                      <tr>-->
<!--                        <th width="30" class="text-left">#</th>-->
<!--                        <th>จำนวนชิ้น</th>-->
<!--                        <th width="70">ราคาต่อชิ้น</th>-->
<!--                        <th width="70">ราคารวม</th>-->
<!--                        <th width="50">เครื่องมือ</th>-->
<!--                      </tr>-->
<!--                      </thead>-->
<!--                      <tbody>-->
<!--                      &lt;!&ndash;            pattern_set_prices&ndash;&gt;-->
<!--                      <tr v-for="(item,index) in list">-->
<!--                        <td> {{index+1}}</td>-->
<!--                        <td>-->

<!--                          <v-text-field-->
<!--                            v-model="item.amount"-->
<!--                            type="number"-->
<!--                            min="0"-->
<!--                            placeholder="ราคาพิเศษสำหรับลายร้าน"-->
<!--                          ></v-text-field>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          <v-text-field-->
<!--                            v-model="item.price"-->
<!--                            type="number"-->
<!--                            min="0"-->
<!--                            placeholder="ราคาพิเศษสำหรับลายร้าน"-->
<!--                          ></v-text-field>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          {{ item.product_type ? item.product_type.product_type_name : '' }}-->
<!--                        </td>-->
<!--                        <td>-->
<!--                          <v-btn-->
<!--                            @click="deleteSubRate(index)"-->
<!--                          >-->
<!--                            <v-icon-->
<!--                              class="mr-2"-->
<!--                              small-->
<!--                              color="danger"-->
<!--                            >-->

<!--                              {{ icons.mdiDelete }}-->
<!--                            </v-icon>-->

<!--                            ลบ-->
<!--                          </v-btn>-->
<!--                        </td>-->

<!--                      </tr>-->
<!--                      </tbody>-->
<!--                    </table>-->
<!--                    <hr class="mb-5" style="border: 0.5px solid lightgrey">-->

<!--                    <v-btn-->
<!--                      style="width: 100%"-->
<!--                      @click="save"-->
<!--                      color="primary">-->
<!--                      บันทึก-->
<!--                    </v-btn>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--            </v-card-text>-->

<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn-->
<!--                color="error"-->
<!--                text-->
<!--                @click="close"-->
<!--              >-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                v-if="editedItem.product_type_id"-->
<!--                color="primary"-->
<!--                text-->
<!--                @click="save"-->
<!--              >-->
<!--                Save-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
<!--        <v-dialog-->
<!--          v-model="dialogDelete"-->
<!--          max-width="600px"-->
<!--        >-->
<!--          <v-card>-->
<!--            <v-card-title class="text-h5 mb-5">-->
<!--              Are you sure you want to delete this item?-->
<!--            </v-card-title>-->
<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn-->
<!--                color="error"-->
<!--                outlined-->
<!--                @click="closeDelete"-->
<!--              >-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="primary"-->
<!--                @click="deleteItemConfirm"-->
<!--              >-->
<!--                OK-->
<!--              </v-btn>-->
<!--              <v-spacer></v-spacer>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ item }">
      {{ product_amounts.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        @click="updateStatusPackage(item.package_id)"
        v-model="item.package_status">
      </v-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:item.promotion_free="{ item }">
      <span v-if="item.start_promotion && item.end_promotion">
        วันที่เริ่ม {{ item.start_promotion ? item.start_promotion : '-' }} <br>
        วันที่สิ้นสุด {{ item.end_promotion ? item.end_promotion : '-' }}
      </span>
      <span v-if="!item.start_promotion && !item.end_promotion">
        -
      </span>
    </template>

    <template v-slot:item.cut_stock="{ item }">
      <span v-if="item.is_stock === 'Y'">ตัดสต๊อค</span>
      <span v-if="item.is_stock !== 'Y'">-</span>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_product from '../../../services/product'
import instance_product_amount from '../../../services/productAmount'

export default {
  components: {DatePicker},
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    id : '',
    product_amounts: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        // value: 'product_amount_id'
        value: 'index'
        // product_amount_id
      },
      {text: 'จำนวน', value: 'product_amount'},
      {text: 'คงเหลือ', value: 'balance'},
      {text: 'ทุนต่อหน่วย(บาท)', value: 'product_costs_present'},
      {text: 'ทุน เพิ่ม/ลด (บาท)', value: 'product_amount'},
      {text: 'วันที่ดำเนินการ', value: 'insert_date'},
      {text: 'เวลาที่ดำเนินการ', value: 'insert_time'},
      {text: 'ตัดจากการตัดสต๊อค', value: 'cut_stock'},
//     if (is_stock === 'Y'){
//   return 'ตัดสต๊อค'
// }else{
//   return '-'
// }
      {text: 'หมายเหตุ', value: 'product_amount_comment'},
      {text: 'ผู้ดำเนินการ', value: 'employee.employee_name'},
      // {text: 'แก้ไขข้อมูล', value: 'actions'},
    ],
    list: [],
    editedIndex: -1,
    editedItem: {
      product_type_id: '',
      amount: 0,
      price: 0,
      list: [],
    },
    defaultItem: {
      product_type_id: '',
      amount: 0,
      price: 0,
      list: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม อัตราเรทราคาสินค้า(ชิ้น)' : 'แก้ไข อัตราเรทราคาสินค้า(ชิ้น)'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.id = this.$route.params.id;
    // this.getUser()
    // this.getPriceRate()
    this.getProductAmount()
  },

  methods: {
    editItem(item) {
      instance_product.show(item.id).then(res => {
        this.editedIndex = this.price_rates.indexOf(item)
        // this.editedItem = {...item}
        this.editedItem = {...item}
        this.list = res.data.data
        this.product_type_id = res.data.product_type
        this.dialog = true
      }).catch(err => {

      });
    },

    deleteItem(item) {
      this.editedIndex = this.price_rates.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    deleteSubRate(index) {
      this.list.splice(index,1)
    },
    UpdateStatus(id, type) {
      // 1 2 3
      var data = {
        id: id,
        type: type
      }
      // $('#UpdateStatus').attr('action', "{{ url('admin/transport_type_updateStatus/:id').'?type=:type' }}".replace(':id', id).replace(':type', type))
      //   .submit()
      instance_product.changeStatus(data).then(res => {

      }).catch(err => {

      });
    },
    addRate(){
      var tmp =  {
        id : '',
        price_rate_id : '',
        amount : 0,
        price : 0,
      }
      this.list.push(tmp)
    },
    deleteItemConfirm() {
      instance_product.destroy(this.editedItem).then(res => {
        // this.price_rates.splice(this.editedIndex, 1)
        this.getPriceRate()
        this.closeDelete()
      }).catch(err => {

      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {
      var data = {
        id : this.editedItem.id,
        product_type_id : this.product_type_id,
        list : this.list,
      }
      if (this.editedIndex > -1) {
        instance_product.update(data)
          .then(res => {
            // Object.assign(this.price_rates[this.editedIndex], this.editedItem
            this.getPriceRate()
            this.close()
          }).catch(err => {

        });
      } else {
        instance_product.store(data)
          .then(res => {
            // this.price_rates.push(this.editedItem)
            this.getPriceRate()
            this.close()
          }).catch(err => {

        });
      }
    },
    getProductAmount() {

      instance_product.getProductAmount(this.id).then(res => {
        this.product_amounts = res.data.data;
      }).catch(err => {

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
